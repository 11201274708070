import React from "react";

import Landingpage from "./courtyard/entrance/Landingpage";

import Landingpagecover from "./courtyard/entrance/Landingpagecover";

import "./css/style.css";

const App = () => {
  return (
    <div>
      <div>
        <Landingpagecover>
          <Landingpage />
        </Landingpagecover>
      </div>
    </div>
  );
};

export default App;
