import React from "react";

const Landingpage = () => {
  // landing page overlay

  // white colored header, light blue zeus-language title

  // info block

  return (
    <div className="container">
      <div className="courtyard-container">
        <div className="inlines courtyard-title"> ZEUS </div>
        <div className="inlines courtyard-bcontainer">
          <div className="courtyard-buttons">
            {/* <div className="vert-text">Documents</div> */}
            Documents
          </div>

          <div className="courtyard-buttons">Download</div>
        </div>
      </div>
    </div>
  );
};

export default Landingpage;
